import showNotification from "../components/extras/showNotification";
import { requestWithoutToken, requestWithToken } from "../helpers/helpers";
import { AUTH_USER, AUTH_USER_FAIL, LOGIN_SUCCESS, LOGOUT } from "./types";

// Authenticate User
export const authUser = () => async (dispatch) => {
  try {
    const response = await requestWithToken("GET", "/api/admin/me");

    dispatch({
      type: AUTH_USER,
      payload: response.data.data,
    });

    return response.data;
  } catch (err) {
    // dispatch(error(err));
    dispatch({ type: AUTH_USER_FAIL });

    return err.response.data;
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    const body = { email, password };

    const response = await requestWithoutToken(
      "POST",
      "/api/admin/signIn",
      body
    );

    if (!response.data?.status) {
      showNotification("Login", response.data.message, "danger");
    }

    if (response.status) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response?.data?.data,
      });
      return response.data;
    }
  } catch (err) {
    return err.response.data;
    // dispatch(error(err));
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
