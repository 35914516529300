import React from "react";
import classNames from "classnames";
import Header, { HeaderLeft, HeaderRight } from "../../../layout/Header/Header";
import useDarkMode from "../../../hooks/useDarkMode";
import Button from "../../../components/bootstrap/Button";
import { reDeployFe } from "../../../actions/user";
import showNotification from "../../../components/extras/showNotification";

const DashboardBookingHeader = () => {
  const { darkModeStatus } = useDarkMode();

  const callReDeploy = async () => {
    const response = await reDeployFe();
    if (response.status) {
      showNotification(
        "Deployment",
        "Request sent. Please wait  for 10 to 20 minutes for the deployment process. ",
        "success"
      );
    } else {
      showNotification("Deployment", response.message, "danger");
    }
  };

  return (
    <Header>
      <HeaderLeft>
        <div className="d-flex align-items-center">
          <div className="row g-4">
            <div className="col-md-auto">
              <div
                className={classNames("fs-3", "fw-bold", {
                  "text-dark": !darkModeStatus,
                })}
              >
                Hi, {JSON.parse(localStorage.getItem("user_data"))?.firstName}!
              </div>
            </div>
          </div>
        </div>
      </HeaderLeft>
      <HeaderRight>
        <Button
          color="info"
          icon="hdd-rack-fill"
          onClick={() => {
            callReDeploy();
          }}
        >
          Re-Deploy
        </Button>
      </HeaderRight>
    </Header>
  );
};

export default DashboardBookingHeader;
