import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";

const Logo = ({ size }) => {
  // return "Udaan";
  return <Avatar src="/img/favicon.ico" size={size} />;
};

Logo.propTypes = {
  size: PropTypes.number,
};

Logo.defaultProps = {
  size: 50,
};

export default Logo;
