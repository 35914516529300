import React from "react";

import DashboardHeader from "../pages/common/Headers/DashboardHeader";
import DashboardBookingHeader from "../pages/common/Headers/DashboardBookingHeader";
import ProfilePageHeader from "../pages/common/Headers/ProfilePageHeader";
import SummaryHeader from "../pages/common/Headers/SummaryHeader";
import ProductsHeader from "../pages/common/Headers/ProductsHeader";
import ProductListHeader from "../pages/common/Headers/ProductListHeader";
import PageLayoutHeader from "../pages/common/Headers/PageLayoutHeader";
import ComponentsHeader from "../pages/common/Headers/ComponentsHeader";
import FormHeader from "../pages/common/Headers/FormHeader";
import ChartsHeader from "../pages/common/Headers/ChartsHeader";
import ContentHeader from "../pages/common/Headers/ContentHeader";
import UtilitiesHeader from "../pages/common/Headers/UtilitiesHeader";
import IconHeader from "../pages/common/Headers/IconHeader";
import ExtrasHeader from "../pages/common/Headers/ExtrasHeader";
import DefaultHeader from "../pages/common/Headers/DefaultHeader";
import {
  componentsMenu,
  dashboardMenu,
  demoPages,
  layoutMenu,
  routesMenu,
  myMenu,
} from "../menu";

const headers = [
  {
    path: dashboardMenu.dashboard.path,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: myMenu.master.subMenu.language.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.chat.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addLanguage.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editLanguage.path,
    element: <></>,
    exact: true,
  },

  {
    path: myMenu.master.subMenu.product.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addProduct.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editProduct.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.master.subMenu.service.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addFrUser.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addService.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editService.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.userMaster.subMenu.frUser.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.userMaster.subMenu.userAdd.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.pages.subMenu.partnerTemplate.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editUser.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.userMaster.subMenu.adminUser.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.master.subMenu.qualification.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addQualification.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editQualification.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.master.subMenu.specialist.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addSpecialist.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editSpecialist.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.moderation.subMenu.otherProduct.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.moderation.subMenu.otherService.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.partnerRegistartion.subMenu.partnerRegistartionList.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.partnerRegistartion.subMenu.partners.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.partnerDetails.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addPartner.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editPartner.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.partnerRegistartion.subMenu.events.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.eventDetails.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addEvent.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editEvent.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.posts.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.pages.subMenu.homeTemplate.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editTemplate.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editPost.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.poll.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addPoll.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.pollOption.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editPoll.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.news.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addNews.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editNews.path,
    element: <></>,
    exact: true,
  },
  // {
  //   path: myMenu.media.path,
  //   element: <></>,
  //   exact: true,
  // },
  {
    path: routesMenu.addMedia.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editMedia.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.tag.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addTag.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editTag.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.mediaList.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.master.subMenu.category.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addCategory.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editCategory.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.master.subMenu.productCategory.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addProductCategory.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editProductCategory.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.editPermission.path,
    element: <></>,
    exact: true,
  },
  // {
  //   path: myMenu.articleCategory.path,
  //   element: <></>,
  //   exact: true,
  // },
  // {
  //   path: routesMenu.addArticleCategory.path,
  //   element: <></>,
  //   exact: true,
  // },
  // {
  //   path: routesMenu.editArticleCategory.path,
  //   element: <></>,
  //   exact: true,
  // },
  {
    path: myMenu.module.path,
    element: <> </>,
    exact: true,
  },
  {
    path: myMenu.appointment.path,
    element: <></>,
    exact: true,
  },
  {
    path: myMenu.callList.path,
    element: <></>,
    exact: true,
  },
  {
    path: routesMenu.addModule.path,
    element: <> </>,
    exact: true,
  },
  {
    path: routesMenu.editModule.path,
    element: <> </>,
    exact: true,
  },
  {
    path: routesMenu.addPost.path,
    element: <> </>,
    exact: true,
  },

  {
    path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
    element: null,
    exact: true,
  },
  {
    path: layoutMenu.pageLayout.subMenu.onlyContent.path,
    element: null,
    exact: true,
  },
  { path: layoutMenu.blank.path, element: null, exact: true },
  { path: demoPages.login.path, element: null, exact: true },
  { path: demoPages.signUp.path, element: null, exact: true },
  { path: demoPages.page404.path, element: null, exact: true },
  { path: demoPages.knowledge.subMenu.grid.path, element: null, exact: true },
  {
    path: dashboardMenu.dashboard.path,
    element: <DashboardHeader />,
    exact: true,
  },
  {
    path: demoPages.projectManagement.subMenu.list.path,
    element: <DashboardHeader />,
    exact: true,
  },
  {
    path: demoPages.pricingTable.path,
    element: <DashboardHeader />,
    exact: true,
  },
  {
    path: dashboardMenu.dashboardBooking.path,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: demoPages.appointment.subMenu.calendar.path,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: demoPages.appointment.subMenu.employeeList.path,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: demoPages.listPages.subMenu.listFluid.path,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: `${demoPages.editPages.path}/*`,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: `${demoPages.appointment.subMenu.employeeID.path}/*`,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: `${demoPages.projectManagement.subMenu.itemID.path}/*`,
    element: <DashboardBookingHeader />,
    exact: true,
  },
  {
    path: demoPages.singlePages.subMenu.fluidSingle.path,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: demoPages.singlePages.subMenu.boxedSingle.path,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: demoPages.sales.subMenu.transactions.path,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: demoPages.chat.subMenu.withListChat.path,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: demoPages.chat.subMenu.onlyListChat.path,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: demoPages.crm.subMenu.dashboard.path,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: demoPages.crm.subMenu.customersList.path,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: `${demoPages.crm.subMenu.customerID.path}/:id`,
    element: <ProfilePageHeader />,
    exact: true,
  },
  {
    path: dashboardMenu.summary.path,
    element: <SummaryHeader />,
    exact: true,
  },
  {
    path: demoPages.gridPages.subMenu.gridBoxed.path,
    element: <ProductsHeader />,
    exact: true,
  },
  {
    path: demoPages.gridPages.subMenu.gridFluid.path,
    element: <ProductsHeader />,
    exact: true,
  },
  {
    path: demoPages.listPages.subMenu.listBoxed.path,
    element: <ProductListHeader />,
    exact: true,
  },
  {
    path: demoPages.sales.subMenu.salesList.path,
    element: <ProductListHeader />,
    exact: true,
  },
  {
    path: demoPages.sales.subMenu.productsGrid.path,
    element: <ProductListHeader />,
    exact: true,
  },
  {
    path: `${demoPages.sales.subMenu.productID.path}/:id`,
    element: <ProductListHeader />,
    exact: true,
  },
  {
    path: `${layoutMenu.asideTypes.path}/*`,
    element: <PageLayoutHeader />,
    exact: true,
  },
  {
    path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
    element: <PageLayoutHeader />,
    exact: true,
  },
  {
    path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
    element: <PageLayoutHeader />,
    exact: true,
  },
  {
    path: `${componentsMenu.components.path}/*`,
    element: <ComponentsHeader />,
  },
  {
    path: `${componentsMenu.forms.path}/*`,
    element: <FormHeader />,
  },
  {
    path: `${componentsMenu.charts.path}/*`,
    element: <ChartsHeader />,
  },
  {
    path: `${componentsMenu.content.path}/*`,
    element: <ContentHeader />,
  },
  {
    path: `${componentsMenu.utilities.path}/*`,
    element: <UtilitiesHeader />,
  },
  {
    path: `${componentsMenu.icons.path}/*`,
    element: <IconHeader />,
  },
  {
    path: `${componentsMenu.extra.path}/*`,
    element: <ExtrasHeader />,
  },
  {
    path: `*`,
    element: <DashboardBookingHeader />,
  },
];

export default headers;
