import { requestWithToken } from "../helpers/helpers";
const fetchUserList = async (pageNo, pageSize, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/admin/users?pageNo=${pageNo}&pageSize=${pageSize}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAdminUserList = async (pageNo, pageSize, search) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/admin/adminUsers?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const createUser = async (formData) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/admin/userAdmin`,
      formData
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deleteUser = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/api/admin/user/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const editUser = async (id, value) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/admin/user/${id}`,
      value
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchUserDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/admin/userDetails/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const changeUserStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/admin/usersStatus/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const bulkUserApproved = async (payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/admin/usersStatus`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchFrUserList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/admin/frUser?pageNo=${pageNo}&pageSize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createFrUser = async (formData) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/admin/frUser`,
      formData
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPermissionList = async () => {
  try {
    const response = await requestWithToken("GET", `/api/admin/permission`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const setUserPermission = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/admin/userPermission/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPermissionDetail = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/admin/userPermission/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const reDeployFe = async () => {
  try {
    const response = await requestWithToken("GET", `/api/admin/feDeploy`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAppointmentList = async (
  pageNo,
  pageSize,
  search,
  startDate,
  endDate,
  status
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate || search || status
        ? `/api/admin/appointmentList?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&search=${search}&status=${status}`
        : `/api/admin/appointmentList?pageNo=${pageNo}&pageSize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPharmacistCall = async (pageNo, pageSize, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/api/admin/callList?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
        : `/api/admin/callList?pageNo=${pageNo}&pageSize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchUserList,
  fetchAdminUserList,
  createUser,
  deleteUser,
  editUser,
  fetchUserDetails,
  changeUserStatus,
  fetchFrUserList,
  createFrUser,
  fetchPermissionList,
  setUserPermission,
  fetchPermissionDetail,
  reDeployFe,
  bulkUserApproved,
  fetchAppointmentList,
  fetchPharmacistCall,
};
