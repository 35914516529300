import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import USERS from "../../common/data/userDummyData";
import { demoPages } from "../../menu";
import {
  DropdownItem,
  DropdownMenu,
} from "../../components/bootstrap/Dropdown";
import Button from "../../components/bootstrap/Button";
import useDarkMode from "../../hooks/useDarkMode";
import Collapse from "../../components/bootstrap/Collapse";
import { NavigationLine } from "../Navigation/Navigation";
import Icon from "../../components/icon/Icon";
import useNavigationItemHandle from "../../hooks/useNavigationItemHandle";
import { logout } from "../../actions/auth";

const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleItem = useNavigationItemHandle();
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const [collapseStatus, setCollapseStatus] = useState(false);

  const { t } = useTranslation(["translation", "menu"]);

  const onLogout = () => {
    dispatch(logout());
    navigate(`../${demoPages.login.path}`);
  };

  return (
    <>
      <div
        className={classNames("user", { open: collapseStatus })}
        role="presentation"
        onClick={() => setCollapseStatus(!collapseStatus)}
      >
        <div className="user-avatar">
          <img
            srcSet={USERS.JOHN.srcSet}
            src={USERS.JOHN.src}
            alt="Avatar"
            width={128}
            height={128}
          />
        </div>
        <div className="user-info">
          <div className="user-name d-flex align-items-center">
            {JSON.parse(localStorage.getItem("user_data"))?.firstName}
            <Icon icon="Verified" className="ms-1" color="info" />
          </div>
          {/* <div className="user-sub-title">{USERS.JOHN.position}</div> */}
        </div>
      </div>
      {/* <DropdownMenu>
        <DropdownItem>
          <Button
            icon="AccountBox"
            onClick={() =>
              navigate(
                `../${demoPages.appointment.subMenu.employeeID.path}/${USERS.JOHN.id}`
              )
            }
          >
            Profile
          </Button>
        </DropdownItem>
        <DropdownItem>
          <Button
            icon={darkModeStatus ? "DarkMode" : "LightMode"}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label="Toggle fullscreen"
          >
            {darkModeStatus ? "Dark Mode" : "Light Mode"}
          </Button>
        </DropdownItem>
      </DropdownMenu> */}

      <Collapse isOpen={collapseStatus} className="user-menu">
        <nav aria-label="aside-bottom-user-menu">
          <div className="navigation">
            {/* <div
              role="presentation"
              className="navigation-item cursor-pointer"
              onClick={() =>
                navigate(
                  `../${demoPages.appointment.subMenu.employeeID.path}/${USERS.JOHN.id}`,
                  handleItem()
                )
              }
            >
              <span className="navigation-link navigation-link-pill">
                <span className="navigation-link-info">
                  <Icon icon="AccountBox" className="navigation-icon" />
                  <span className="navigation-text">{t("menu:Profile")}</span>
                </span>
              </span>
            </div> */}
            <div
              role="presentation"
              className="navigation-item cursor-pointer"
              onClick={() => {
                setDarkModeStatus(!darkModeStatus);
                handleItem();
              }}
            >
              <span className="navigation-link navigation-link-pill">
                <span className="navigation-link-info">
                  <Icon
                    icon={darkModeStatus ? "DarkMode" : "LightMode"}
                    color={darkModeStatus ? "info" : "warning"}
                    className="navigation-icon"
                  />
                  <span className="navigation-text">
                    {darkModeStatus ? t("menu:DarkMode") : t("menu:LightMode")}
                  </span>
                </span>
              </span>
            </div>
          </div>
        </nav>
        <NavigationLine />
        <nav aria-label="aside-bottom-user-menu-2">
          <div className="navigation">
            <div
              role="presentation"
              className="navigation-item cursor-pointer"
              onClick={() => onLogout()}
            >
              <span className="navigation-link navigation-link-pill">
                <span className="navigation-link-info">
                  <Icon icon="Logout" className="navigation-icon" />
                  <span className="navigation-text">{t("menu:Logout")}</span>
                </span>
              </span>
            </div>
          </div>
        </nav>
      </Collapse>
    </>
  );
};

export default User;
